import React from 'react';

import useToken from '../../useToken';
import useID from '../../useID';

import './NavBar.css';

export default function LogOutButton() {
    const { token, setToken } = useToken();
    const { ID } = useID();

    async function handleLogout() {

        const requestBody = {
            id: ID
        };
        console.log('logging out')
        console.log(requestBody)
        fetch('https://groep16.webdev.ilabt.imec.be/api/users/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `ApiKey ${token}`
            },
            body: requestBody
        }).then((response) => {
            if (response.ok) {
                console.log('Logged out');
                setToken('');
                window.location.href = '/';
            } else {
                console.log('Oopsie');
            }
        }
        ).catch((e) => console.log(e));
    }
    

    return (
        <a href="/" className="logout-link" onClick={handleLogout}>
            Logout
        </a>
    );
}
