export default function request({ method, apiRoute, bodyArgs, token }, onSuccess = () => window.location.reload(), onError = (error) => console.error(error)) {
  if (!bodyArgs) {
    return;
  }

  const headers = {
    'Authorization': `ApiKey ${token}`
  };

  let body;
  if (bodyArgs instanceof FormData) {
    body = bodyArgs;
  } else {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(bodyArgs);
  }

  fetch(`https://groep16.webdev.ilabt.imec.be/api${apiRoute}`, {
    method: method,
    headers: headers,
    body: body
  })
    .then(async (res) => {
      if (res.ok) {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const responseData = await res.json();
          onSuccess(responseData);
        } else {
          onSuccess();
        }
      } else {
        onError(res.statusText);
      }
    })
    .catch((err) => onError(err.message));
}
