import React, { Suspense, createContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import useToken from './useToken';
import useID from './useID';
import useAdmin from './useAdmin';
import { ProductsProvider } from './api_communication/useProducts';
import TopNavigationBar from './components/NavigationBar/NavBar';

const LazyLogin = React.lazy(() => import('./components/login/Login'));
const LazyProducts = React.lazy(() => import('./components/product/Products'));
const LazyUsers = React.lazy(() => import('./components/users/Users'));
const LazyNewUserForm = React.lazy(() => import('./components/users/NewUser'));
const LazyUserProfile = React.lazy(() => import('./components/users/UserProfile'));
const LazyPurchaseForm = React.lazy(() => import('./components/product/PurchaseForm'));

// wordt gebruikt om navigatie tussen productoverzicht en enkele producten zo snel mogelijk te maken
export const ProductsContext = createContext();

function App() {
  const { token, setToken } = useToken();
  const { setAdmin } = useAdmin();
  const { ID, setId } = useID();

  if (!token) {
    return <LazyLogin setToken={setToken} setId={setId} setAdmin={setAdmin} />;
  }

  // navigate(`/users/${ID}/`);
  return (
    <div className="wrapper">
      <TopNavigationBar id={ID} />
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Navigate to={`/users/${ID}/`} />} />
            <Route path="/products/*" // Voor de productpaden een productprovider voorzien.
              element={ // op deze manier kan je gemakkelijk navigeren tussen verschillende producten, zonder dat je alle producten ooit opnieuw moet vragen.
                <ProductsProvider>
                  <Routes>
                    <Route path="/" element={<LazyProducts />} />
                    <Route path="/:id" element={<LazyPurchaseForm />} />
                  </Routes>
                </ProductsProvider>
              }
            />
            <Route path="/users" element={<LazyUsers />} />
            <Route path="/users/new_user" element={<LazyNewUserForm />} />
            <Route path="/users/:id" element={<LazyUserProfile />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
