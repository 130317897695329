import useApiGet from './UseApiGet';
import request from './post-patch';
import useToken from '../useToken';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ProductsContext } from '../App';

// Deze code zorgt ervoor dat je kan navigeren tussen /products en /products/:id zonder dat de producten
// telkens opnieuw moeten opgevraagd worden.
export function ProductsProvider ({ children }) {
    const productsHook = useProducts();
    return (
        <ProductsContext.Provider value={productsHook}>
            {children}
        </ProductsContext.Provider>
    )
}

const mapToDutchFieldName = {'stock':'aantal_in_stock', 'name':'naam', 'price':'prijs'}

export default function useProducts(){
    const { token } = useToken();
    const { data, loading, error } = useApiGet("/products");
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    
    useEffect(() => {
        if (data) {
          setItems([...data]);
        }
      }, [data]);
    
    const editProductFieldCall = async (id, fieldName, newValue, onSuccess) => request({apiRoute:`/products/${id}/${fieldName}`, method:"PATCH", bodyArgs:{
        [fieldName]: newValue,
    }, token:token}, onSuccess);

    const editProductField = async (productId, fieldName, newValue, afterChange) => {
        // om het een generieke methode te houden moet er spijtig genoeg eerst een mapping gebeuren voor de veldnamen
        // dit is omdat de namen van de velden binnen de API in het engels zijn, maar in de data(bank) in het Nederlands.
        const dutchFieldName = mapToDutchFieldName[fieldName];
        // we doen eerst een call naar de api om de verandering te maken, en als we een OK response krijgen, maken we lokaal ook de aanpassing
        // zo moet de data niet bij elke kleine aanpassing opnieuw opgevraagd worden.
        editProductFieldCall(productId, fieldName, newValue, () => {
            setItems(prevItems =>
                prevItems.map((item, i) => (item.id === productId ? {...item, [dutchFieldName]:newValue} : item))
            );
            // je kan ook een actie meegeven afterChange, die na een succesvolle verandering uitvoert.
            afterChange();
        })
    }
    const editImage = async (productId, image, imagePreview, afterChange) => {
        const formData = new FormData();
        formData.append('image', image);
        request({apiRoute:`/products/${productId}/image`, method:"PATCH", bodyArgs:formData, token:token},
        () => {
            setItems(prevItems =>
                prevItems.map((item, i) => (item.id === productId ? {...item, image:imagePreview} : item))
            );
            afterChange();
        });
    }

    const addProductCall = async (formData, onSuccess) => request({apiRoute:"/products", method:"POST", bodyArgs:formData, token:token}, onSuccess);
    
    const addProduct = async (formData, onSuccess) => {
        addProductCall(formData, (resJson) => {
            setItems([newFakeProduct(resJson.id, formData), ...items]);
            onSuccess();
        })
    }

    const buyProductCall = async (productId, userId, amount, onSuccess) => request({method:"POST", apiRoute:"/purchases", bodyArgs:{
        'user': userId,
        'product': productId,
        'amount': amount
    }, token:token}, onSuccess)
    
    const buyProduct = async (productId, userId, amount) => {
        buyProductCall(productId, userId, amount, () => {
            setItems(prevItems =>
                prevItems.map((item, i) => (item.id === productId ? {...item, 'aantal_in_stock':(item.aantal_in_stock-amount)} : item))
            );
            navigate("/products");
        });
    }

    return {
        products:items,
        loading:loading,
        error:error,
        addProduct:addProduct,
        editName: (id, name, afterChange) => editProductField(id, 'name', name, afterChange),
        editPrice: (id, price, afterChange) => editProductField(id, 'price', price, afterChange),
        editStock: (id, stock, afterChange) => editProductField(id, 'stock', stock, afterChange),
        editImage: editImage,
        buyProduct:buyProduct
    }
}

function newFakeProduct(id, formData){
    var returnValue = {"id":id};
    for (let [key, value] of formData.entries()) {
        const dutchFieldName = mapToDutchFieldName[key] || key;
        returnValue = {...returnValue, [dutchFieldName]:value}
    }
    return returnValue;
}
