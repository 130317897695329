import { useState } from 'react';

export default function useAdmin() {
    const getToken = () => {
        return JSON.parse(sessionStorage.getItem('admin'));
    };
    const [admin, setAdmin] = useState(getToken());

    const saveToken = token => {
        sessionStorage.setItem('admin', token);
        setAdmin(token);
    };

    return {
    setAdmin: saveToken,
    admin
    }
}
