import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMartiniGlassCitrus, faUsers, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'; // Import the icons you need
import './NavBar.css';
import LogOutButton from './LogOutButton';

export default function TopNavigationBar({ id }) {
    return (
        <div className="topnav">
            <a href={`/users/${id}`}>
                <FontAwesomeIcon icon={faUser} />
                <span className="nav-text">Your Profile</span>
            </a>
            <a href="/products">
                <FontAwesomeIcon icon={faMartiniGlassCitrus} />
                <span className="nav-text">Products</span>
            </a>
            <a href="/users">
                <FontAwesomeIcon icon={faUsers} />
                <span className="nav-text">Users</span>
            </a>
            <LogOutButton>
            <FontAwesomeIcon icon={faRightFromBracket} />
                <span className="nav-text">Logout</span>
            </LogOutButton>
        </div>
    );
}
