import { useEffect, useState, useRef } from 'react';
import useToken from '../useToken';

export default function useApiGet(apiRoute, loadInBlocks) {
    const { token } = useToken();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [lastPageReached, setLastPageReached] = useState(false);
    const hasCalledToFetchInitialData = useRef(false); // bijhouden of de eerste data al opgevraagd is. zo wordt die niet te veel opgevraagd.

    const get = (isInitialGet = false) => {
      // Deze eerste check aan het begin van de functie is omdat het kan zijn dat de useEffect 2 keer wordt opgeroepen
      // deze check zorgt dat de data dan geen 2e keer wordt opgevraagd.
      if (!token || (isInitialGet && hasCalledToFetchInitialData.current)) {
        return;
      }
      if (isInitialGet){
        hasCalledToFetchInitialData.current = true;
      }

      var url = `https://groep16.webdev.ilabt.imec.be/api${apiRoute}`

      if (loadInBlocks){
        url = url + `?page=${page}`;
      }
  
      fetch(url, {
        method: "GET",
        headers: {
          'Authorization': `ApiKey ${token}`
        }
      })
      .then(
        (res) => {
          if (res.ok){
            return res.json()
          }else{
            setError(`${res.status} ${res.statusText}`);
            setLoading(false);
            return;
          }
        }
      )
      .then((data) => {
        if (loadInBlocks){
          setData(data.items);
        }else{
          setData(data);
        }
        setLoading(false)
 
        setPage(page + 1);
        if (data.currentPage >= data.totalPages){
          setLastPageReached(true);
        }
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
    }
    
  
    useEffect(() => {
        get(true); // true meegeven aan de get om aan te geven dat dit de eerste get is.
    }, []);

    var returnValue = {
      data:data,
      loading:loading,
      error:error
    }

    // Enkel als we laden in blokken, en als het laatste blok nog niet bereikt is,
    // geven we de mogelijkheid mee om de next page op te vragen.
    if (loadInBlocks && !lastPageReached){
      returnValue = {...returnValue, nextPage:get};
    }
    
    return returnValue

}