import { useState } from 'react';

export default function useID() {
    const getToken = () => {
        return JSON.parse(sessionStorage.getItem('ID'));
    };
    const [ID, setID] = useState(getToken());

    const saveToken = token => {
        sessionStorage.setItem('ID', token);
        setID(token);
    };

    return {
    setId: saveToken,
    ID
    }
}
